/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Button from "./Button";
import useEndpoint from "../hooks/useEndpoint";
import Error from "../pages/Error";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import Toggle from "./Toggle";
import PaymentMethodSelect from "./PaymentMethodSelect";

const PaymentForm = ({ booking }) => {
	const navigate = useNavigate();
	const [token] = useLocalStorage("token", null);
	const { user, loading, error } = useEndpoint("/account", "user");
	const [payLoading, setPayLoading] = useState(false);
	const [payMessage, setPayMessage] = useState(null);
	const [paySuccess, setPaySuccess] = useState(null);
	const [intervalId, setIntervalId] = useState(null);
	const [savePaymentMethod, setSavePaymentMethod] = useState(false);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("new");

	useEffect(() => {
		return function () {
			if (!intervalId)
				clearInterval(intervalId);
		};
	});

	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!stripe || !elements)
			return;

		setPayMessage(null);
		setPayLoading(true);

		const options = {};

		if (selectedPaymentMethod === "new") {
			options.payment_method = {
				card: elements.getElement(CardElement),
				billing_details: {
					email: user.email,
					name: `${user.firstName} ${user.lastName}`
				}
			};
		} else
			options.payment_method = selectedPaymentMethod.id;

		if (savePaymentMethod)
			options.setup_future_usage = "on_session";

		const result = await stripe.confirmCardPayment(booking.paymentIntent.clientSecret, options);

		if (result.error) {
			setPayMessage(result.error.message);
			setPayLoading(false);
		} else if (result.paymentIntent.status === "succeeded") {
			setPaySuccess("Payment complete! Finalising your booking...");
			const id = setInterval(async () => {
				const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/bookings/${booking.id}`, {
					headers: {
						authorization: "Bearer " + token
					}
				});

				const body = await response.json();

				if (body.booking.status === 1) {
					clearInterval(id);
					navigate(`/pay/${booking.id}/success`);
				}
			}, 1000);

			setIntervalId(id);
		}
	};

	const options = {
		style: {
			base: {
				backgroundColor: "#d0d0dc",
				color: "#0e1015",
				fontWeight: "700",
				fontFamily: "Circe, sans-serif",
				fontSize: "16px",
				fontSmoothing: "antialiased",
				":focus": {
					iconColor: "#ea580c"
				}
			},
			invalid: {
				color: "#ef4444"
			}
		}
	};

	if (error) {
		return (
			<Error code={error.statusCode}/>
		);
	}

	return (
		<form onSubmit={handleSubmit}>
			<PaymentMethodSelect selected={selectedPaymentMethod} setSelected={setSelectedPaymentMethod}/>
			{selectedPaymentMethod === "new" && (
				<>
					<CardElement options={options}/>
					<div className="my-4">
						<Toggle
							label="Save for future payments"
							enabled={savePaymentMethod} setEnabled={setSavePaymentMethod}
						/>
					</div>
				</>
			)}
			{payMessage && (
				<div className="px-3 py-2 mb-4 rounded-lg bg-red-500 text-bone font-bold flex items-center">
					<ExclamationCircleIcon className="inline-block flex-shrink-0 w-5 h-5 mr-2"/>
					{payMessage}
				</div>
			)}
			{paySuccess ? (
				<div className="px-3 py-2 mb-4 rounded-lg bg-green-600 text-bone font-bold flex items-center">
					<CheckCircleIcon className="inline-block flex-shrink-0 w-5 h-5 mr-2"/>
					{paySuccess}
				</div>
			) : (
				<Button
					text={"Pay"}
					loading={loading || payLoading || !stripe || !elements}
					type="submit" className="w-full"
				/>
			)}
		</form>
	);
};

export default PaymentForm;
