import React from "react";
import { Link } from "react-router-dom";
import { BanIcon, FireIcon, LocationMarkerIcon } from "@heroicons/react/outline";

const Error = ({ code }) => {
	let icon;
	let heading;
	let description;

	if (code === 404) {
		icon = <LocationMarkerIcon className="h-16 w-16 text-primary" />;
		heading = "404 • Not Found";
		description = "We're not sure what you were trying to find, but it's definitely not here";
	} else if (code === 403 || code === 401) {
		icon = <BanIcon className="h-16 w-16 text-primary" />;
		heading = `${code} • Unauthorized`;
		description = "It looks like you don't have access to this page";
	} else {
		icon = <FireIcon className="h-16 w-16 text-primary" />;
		heading = `${code ? code : 500} • Internal Server Error`;
		description = "Something went wrong when loading this page";
	}

	return (
		<div className="flex items-center text-center justify-center text-charcoal flex-grow flex-col h-full">
			{icon}
			<h1 className="font-extrabold text-4xl mt-3 mb-1">
				{heading}
			</h1>
			<p className="text-lg font-bold mb-5">
				{description}
			</p>
			<Link to="/">
				<a className="bg-primary hover:bg-primary-darker p-2 px-4 text-bone rounded-lg font-bold">
					Back to Homepage
				</a>
			</Link>
		</div>
	);
};

export default Error;
