import React from "react";
import { Switch } from "@headlessui/react";

const Toggle = ({ label, enabled, setEnabled }) => {
	return (
		<Switch.Group>
			<div className="flex items-center">
				<Switch
					checked={enabled}
					onChange={setEnabled}
					className={`${
						enabled ? "bg-primary" : "bg-gray-400"
					} relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
				>
					<span className={
						`${enabled ? "translate-x-6" : "translate-x-1"} inline-block w-4 h-4 transform bg-white rounded-full transition-transform`
					}/>
				</Switch>
				{label && <Switch.Label className="ml-4 font-bold text-charcoal">{label}</Switch.Label>}
			</div>
		</Switch.Group>
	);
};

export default Toggle;
