import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl";
import Helmet from "react-helmet";

import useEndpoint from "../hooks/useEndpoint";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;


const Map = ({ startLocation, endLocation }) => {
	const mapContainerRef = useRef(null);
	const [map, setMap] = useState(null);

	const { locations } = useEndpoint("/locations", "locations");

	const getColourForLocation = location => {
		if (location.id === startLocation)
			return "#66bb6a";
		else if (location.id === endLocation)
			return "#991b1b";

		return "#ea580c";
	};

	useEffect(() => {
		const newMap = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: "mapbox://styles/mapbox/streets-v11",
			center: [-1.544, 53.796543],
			zoom: 14.0
		});

		newMap.dragRotate.disable();

		setMap(newMap);

		return () => {
			if (map)
				map.remove();
		};
	}, []);

	useEffect(() => {
		const items = [];
		if (locations && map) {
			locations.forEach((location) => {
				const popup = new mapboxgl.Popup({ offset: 25 }).setText(
					location.name
				);

				const marker = new mapboxgl.Marker({
					color: getColourForLocation(location)
				})
					.setLngLat([location.longitude, location.latitude])
					.setPopup(popup);

				marker.addTo(map);

				items.push(popup);
				items.push(marker);

				if (location.id === startLocation) {
					map.flyTo({
						center: [location.longitude, location.latitude],
						zoom: 17,
						essential: false
					});
				}
			});
		}

		return () => {
			items.forEach(item => {
				item.remove();
			});
		};
	}, [locations, startLocation, endLocation]);

	return (
		<div className="sticky top-0 flex w-full md:w-2/3 h-96 md:h-auto md:flex-grow">
			<Helmet>
				<link href='https://api.mapbox.com/mapbox-gl-js/v2.7.0/mapbox-gl.css' rel='stylesheet' />
			</Helmet>
			<div ref={mapContainerRef} className="w-full" />
		</div>
	);
};

export default Map;
