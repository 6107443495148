import React, { Fragment } from "react";
import useEndpoint from "../hooks/useEndpoint";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/outline";
import Spinner from "./Spinner";
import { CreditCardIcon, CheckIcon } from "@heroicons/react/solid";

const PaymentMethodSelect = ({ selected, setSelected }) => {
	const { paymentMethods, loading, error } = useEndpoint("/account/payment-methods", "paymentMethods");

	if (loading || error) {
		return (
			<div className="border-2 border-neutral-400 bg-bone-darker rounded-lg p-3 h-10 mb-4 flex items-center justify-center">
				<Spinner />
			</div>
		);
	}

	const options = ["new", ...paymentMethods];

	return (
		<Listbox value={selected} onChange={setSelected}>
			<div className="relative">
				<Listbox.Button className="relative w-full py-2 pl-3 h-10 pr-10 mb-4 text-left bg-bone-darker rounded-lg border-2 border-neutral-400 cursor-default focus:outline-none sm:text-sm">
					{selected === "new" ? (
						<div className="font-bold items-center flex gap-2">
							<CreditCardIcon className="w-5 h-5 text-primary" />
							<span>New Payment Method</span>
						</div>
					) : (
						<div className="font-bold items-center flex gap-2">
							<CreditCardIcon className="w-5 h-5 text-primary" />
							<span>{selected.brand.substring(0, 1).toUpperCase()}{selected.brand.substring(1)} ending in {selected.last4}</span>
						</div>
					)}
					<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
						<SelectorIcon
							className="w-5 h-5 text-neutral-600"
							aria-hidden="true"
						/>
					</span>
				</Listbox.Button>
				<Transition
					as={Fragment}
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Listbox.Options className="absolute z-20 w-full mt--2 overflow-auto text-base bg-bone-darker border-2 border-neutral-400 rounded-lg shadow-lg max-h-60 focus:outline-none sm:text-sm">
						{options.map((paymentMethod, i) => (
							<Listbox.Option key={i} value={paymentMethod} className={({ active }) =>
								`cursor-default flex items-center justify-between font-bold select-none relative h-10 py-2 px-4 ${
									active ? "text-primary bg-bone" : "text-charcoal"
								}`
							}>
								{({ selected: selectedItem }) => (
									<>
										<span className="block truncate">
											{paymentMethod === "new"
												? "New Payment Method"
												: paymentMethod.brand.substring(0, 1).toUpperCase() + paymentMethod.brand.substring(1) + " ending in " + paymentMethod.last4
											}
										</span>
										{selectedItem && <CheckIcon className="w-6 h-6 text-primary" />}
									</>
								)}
							</Listbox.Option>
						))}
					</Listbox.Options>
				</Transition>
			</div>
		</Listbox>
	);
};

export default PaymentMethodSelect;
