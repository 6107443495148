import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CreditCardIcon, LightningBoltIcon, XIcon, PlusIcon } from "@heroicons/react/solid";
import Container from "../components/Container";
import Locations from "../components/Locations";
import Products from "../components/Products";
import Button from "../components/Button";
import useToasts from "../hooks/useToasts";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import useEndpoint from "../hooks/useEndpoint";
import Spinner from "../components/Spinner";
import Error from "./Error";
import Timer from "../components/Timer";
import Modal from "../components/Modal";
import Map from "../components/Map";
import Textarea from "../components/Textarea";

const Book = () => {
	const toast = useToasts();
	const navigate = useNavigate();
	const { booking, loading, error } = useEndpoint("/bookings/active", "booking");
	const [token] = useLocalStorage("token", null);
	const [bookingLoading, setBookingLoading] = useState(false);
	const [returnLoading, setReturnLoading] = useState(false);
	const [cancelLoading, setCancelLoading] = useState(false);
	const [cancelOpen, setCancelOpen] = useState(false);
	const [startLocation, setStartLocation] = useState(null);
	const [endLocation, setEndLocation] = useState(null);
	const [product, setProduct] = useState(null);
	const [, setRandom] = useState(0);
	const [extendLoading, setExtendLoading] = useState(false);
	const [extendProduct, setExtendProduct] = useState(null);
	const [extendModalOpen, setExtendModalOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [feedbackText, setFeedbackText] = useState("");
	const [discount, setDiscount] = useState(false);
	const [fetched, setFetched] = useState(false);

	const checkDiscount = (async () => {
		if (!fetched) {
			try {
				let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/account`, {
					method: "GET",
					headers: {
						"content-type": "application/json",
						authorization: "Bearer " + token
					}
				});

				let body = await response.json();

				if (body.user.discount === true)
					setDiscount(true);
				else
					setDiscount(false);

				setFetched(true);
			} catch (err) {
				if (!err.response)
					err.response = { message: "An unknown error occurred" };
				else if (!err.response.message)
					err.response.message = "An unknown error occurred";

				toast("Error!", err.response.message, 5000, "bg-red-500");
			}
		}
	});

	const closeModal = () => {
		setIsOpen(false);
		navigate("/");
	};

	const handleStartBooking = async () => {
		try {
			if (!startLocation) {
				toast("Error!", "Please select a start location", 5000, "bg-red-500");
				return;
			}

			if (!endLocation) {
				toast("Error!", "Please select a destination location", 5000, "bg-red-500");
				return;
			}

			if (!product) {
				toast("Error!", "Please select a hire duration", 5000, "bg-red-500");
				return;
			}

			setBookingLoading(true);

			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/bookings`, {
				method: "POST",
				body: JSON.stringify({
					startLocation,
					endLocation,
					product
				}),
				headers: {
					"content-type": "application/json",
					authorization: "Bearer " + token
				}
			});

			let body = await response.json();

			if (response.ok)
				navigate(`/pay/${body.booking.id}`);
			else
				toast("Error!", body.message, 5000, "bg-red-500");

			setBookingLoading(false);
		} catch (err) {
			setBookingLoading(false);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	const handleSendFeedback = async () => {
		try {
			setReturnLoading(true);

			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/feedback`, {
				method: "POST",
				headers: {
					authorization: "Bearer " + token
				},
				body: JSON.stringify({
					text: feedbackText,
					bookingID: booking.id
				})
			});

			let body = await response.json();

			if (response.ok) {
				setIsOpen(false);
				navigate("/");
			} else
				toast("Error!", body.message, 5000, "bg-red-500");

			setReturnLoading(false);
		} catch (err) {
			setReturnLoading(false);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	const handleReturnScooter = async () => {
		try {
			setReturnLoading(true);

			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/bookings/return`, {
				method: "POST",
				headers: {
					authorization: "Bearer " + token
				}
			});

			let body = await response.json();

			if (response.ok) {
				toast("Success!", "Scootr returned successfully, thank you!", 3000, "bg-green-500");
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			} else
				toast("Error!", body.message, 5000, "bg-red-500");

			setReturnLoading(false);
		} catch (err) {
			setReturnLoading(false);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	const handleCancelBooking = async () => {
		try {
			setCancelLoading(true);

			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/bookings/cancel`, {
				method: "POST",
				headers: {
					authorization: "Bearer " + token
				}
			});

			let body = await response.json();

			if (response.ok) {
				toast("Success!", "Booking cancelled successfully, you should receive a refund shortly!", 3000, "bg-green-500");
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			} else
				toast("Error!", body.message, 5000, "bg-red-500");

			setCancelLoading(false);
		} catch (err) {
			setCancelLoading(false);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	const handleExtendBooking = async () => {
		try {
			if (!extendProduct) {
				toast("Error!", "Please select a hire duration", 5000, "bg-red-500");
				return;
			}

			setExtendLoading(true);

			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/bookings/extend`, {
				method: "POST",
				body: JSON.stringify({
					product: extendProduct
				}),
				headers: {
					authorization: "Bearer " + token
				}
			});

			let body = await response.json();

			if (response.ok)
				navigate(`/extend/${body.paymentIntent.clientSecret}`);
			else
				toast("Error!", body.message, 5000, "bg-red-500");

			setExtendLoading(false);
		} catch (err) {
			setExtendLoading(false);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	const handleTextInput = (event) => {
		setFeedbackText(event.target.value);
	};

	// This is a hack to force a rerender for the progress bar in the timer
	useEffect(() => {
		const id = setInterval(() => {
			setRandom(Math.random());
		}, 1000);

		return () => clearInterval(id);
	});

	if (loading) {
		return (
			<div className="flex justify-center items-center flex-grow">
				<Spinner size="h-12 w-12" />
			</div>
		);
	}

	if (error) {
		if (error.statusCode === 404 && error.data.code === "ENTITY_NOT_FOUND") {
			checkDiscount();
			return (
				<div className="flex flex-col md:flex-row flex-grow divide-y-2 md:divide-y-0 md:divide-x-2 divide-primary h-screen">
					<Map startLocation={startLocation} endLocation={endLocation} setSelected={setStartLocation} />
					<div className="h-2/3 md:h-auto flex-grow p-4 overflow-y-auto scroll-window">
						<h2 className="text-primary text-center text-2xl font-bold mb-2">Book a Scootr</h2>
						<label className="text-lg text-primary font-semibold">Start Location</label>
						<Locations selected={startLocation} setSelected={setStartLocation} />
						<div className="mb-6" />
						<label className="text-lg text-primary font-semibold">Destination Location</label>
						<Locations hideAvailability selected={endLocation} setSelected={setEndLocation} />
						<div className="mb-6" />
						<label className="text-lg text-primary font-semibold">Hire Duration<span className="text-green-500">{discount ? "  (10% discount applied)" : ""}</span></label>
						<Products selected={product} setSelected={setProduct} discount={discount} />
						<Button
							text="Continue to Payment"
							iconLeft={<CreditCardIcon className="w-5 h-5 mr-1" />}
							className="w-full h-12 mt-6"
							loading={bookingLoading}
							onClick={handleStartBooking}
						/>
					</div>
				</div>
			);
		}

		return (
			<div className="flex justify-center items-center flex-grow">
				<Error code={error.statusCode} />
			</div>
		);
	}

	return (
		<>
			<div className="flex items-center justify-center flex-grow">
				<Container small className="p-3 justify-center">
					<h3 className="font-bold text-2xl text-center text-primary">Your Current Booking:</h3>
					<div className="px-16 my-6">
						<Timer duration={booking.product.period * 1000} startTime={new Date(booking.date).getTime()} />
					</div>
					{new Date(booking.date).getTime() + 300000 >= Date.now() ? (
						<Button
							className="w-full max-w-[20rem] mx-auto mb-4"
							text="Cancel Booking"
							backgroundColor="bg-red-500 hover:bg-red-600"
							iconLeft={<XIcon className="w-5 h-5 mr-1" />}
							onClick={() => setCancelOpen(true)}
						/>
					) : (
						<Button
							className="w-full max-w-[20rem] mx-auto mb-4"
							text="Return Scootr"
							iconLeft={<LightningBoltIcon className="w-5 h-5 mr-1" />}
							onClick={handleReturnScooter} loading={returnLoading}
						/>
					)}
					<Button
						className="w-full max-w-[20rem] mx-auto"
						text="Extend Booking"
						iconLeft={<PlusIcon className="w-5 h-5 mr-1" />}
						onClick={() => setExtendModalOpen(true)}
					/>
				</Container>
			</div>

			<Modal className="max-w-lg" open={extendModalOpen} setOpen={() => setExtendModalOpen(false)}>
				<div className="flex justify-between items-center mb-2">
					<Dialog.Title
						as="h3"
						className="text-lg font-bold leading-6 text-charcoal"
					>
						Extend Booking
					</Dialog.Title>
					<div className="hover:bg-bone-darker p-1 rounded-lg cursor-pointer" onClick={() => setExtendModalOpen(false)}>
						<XIcon className="w-6 h-6" />
					</div>
				</div>
				<p className="text-charcoal mb-4">
					Please select the time frame you&apos;d like to extend to:
				</p>
				<Products rate={booking.product.rate} selected={extendProduct} setSelected={setExtendProduct} />
				<Button
					className="w-full mt-4"
					text="Continue"
					backgroundColor="bg-primary hover:bg-primary-darker"
					onClick={handleExtendBooking} loading={extendLoading}
				/>
			</Modal>

			<Modal open={cancelOpen} setOpen={() => setCancelOpen(false)}>
				<div className="flex justify-between items-center mb-2">
					<Dialog.Title
						as="h3"
						className="text-lg font-bold leading-6 text-charcoal"
					>
						Cancel Booking
					</Dialog.Title>
					<div className="hover:bg-bone-darker p-1 rounded-lg cursor-pointer" onClick={() => setCancelOpen(false)}>
						<XIcon className="w-6 h-6" />
					</div>
				</div>
				<p className="text-charcoal mb-4">
					Are you sure you want to cancel your booking? You&apos;ll receive a full refund.
				</p>
				<Button
					className="w-full"
					text="Cancel Booking"
					backgroundColor="bg-red-500 hover:bg-red-600"
					onClick={handleCancelBooking} loading={cancelLoading}
				/>
			</Modal>

			<Transition appear show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={closeModal}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
					&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
								<Dialog.Title
									as="h3"
									className="text-lg font-medium leading-6 text-primary font-extrabold"
								>
								Return Successful!
								</Dialog.Title>
								<div className="mt-2">
									<p className="text-sm text-gray-500 mb-4">
									Your Scootr has been returned successfully! If you had any issues with your ride, please leave details below!
									</p>
									<Textarea value={feedbackText} onInput={handleTextInput} placeholder="Feedback"/>
								</div>

								<div className="mt-4">
									<button
										type="button"
										className={"inline-flex justify-center px-4 py-2 text-sm font-medium text-charcoal " +
										"bg-bone border border-transparent mr-2 rounded-md hover:bg-blue-200 focus:outline-none " +
										"focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"}
										onClick={closeModal}
									>
									Close
									</button>
									<button
										type="button"
										className={"inline-flex justify-center px-4 py-2 text-sm font-medium text-bone " +
										"bg-primary border border-transparent mr-2 rounded-md hover:bg-blue-200 focus:outline-none " +
										"focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"}
										onClick={handleSendFeedback}
									>
									Send!
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default Book;
