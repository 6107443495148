import React from "react";
import { useParams } from "react-router-dom";
import Container from "../components/Container";
import ExtendPaymentForm from "../components/ExtendPaymentForm";

const Extend = () => {
	const { id } = useParams();

	return (
		<div className="flex justify-center items-center mt-8 px-2">
			<Container small>
				<h3 className="text-2xl text-center font-bold text-primary mb-4">Extend your Booking</h3>
				<ExtendPaymentForm paymentIntent={id} />
			</Container>
		</div>
	);
};

export default Extend;
