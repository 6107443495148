import React from "react";

const Badge = ({ text, color }) => {
	return (
		<div className={`rounded-full px-3 font-bold ${color ? color : "bg-green-500 text-bone"}`}>
			{text}
		</div>
	);
};

export default Badge;
