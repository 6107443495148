import React from "react";
import useEndpoint from "../hooks/useEndpoint";
import Spinner from "./Spinner";
import { CheckIcon } from "@heroicons/react/outline";

const Products = ({ selected, setSelected, rate, discount }) => {
	const { products, loading, error } = useEndpoint("/products", "products");

	if (loading) {
		return (
			<div className="border-2 border-primary rounded-xl p-3 flex items-center justify-center">
				<Spinner size="h-8 w-8" />
			</div>
		);
	}

	if (error) {
		return (
			<div className="border-2 border-primary rounded-xl p-3 flex items-center justify-center">
				<p className="text-primary font-bold text-center">
					Unable to load product information. Please try again later.
				</p>
			</div>
		);
	}

	let filteredProducts;

	if (rate)
		filteredProducts = products.filter(p => p.rate > rate);
	else
		filteredProducts = products;

	return (
		<div className="border-2 border-primary rounded-xl flex flex-col items-center">
			{filteredProducts.sort((a, b) => a.rate > b.rate ? 1 : -1).map(product => {
				return (
					<div key={product.id} className={
						`${selected === product.id ? "bg-primary" : "hover:bg-bone-darker"} ` +
						"p-3 w-full flex flex-col border-b-2 border-primary cursor-pointer last:border-0 " +
						"transition duration-400 first:rounded-t-inner-xl last:rounded-b-inner-xl"
					} onClick={() => setSelected(product.id)}>
						<div className="flex justify-between items-center">
							<p className={`${selected === product.id ? "text-bone" : "text-primary"} font-bold text-lg leading-none`}>
								{product.name} &bull; £{discount? ((product.rate / 100)*0.9).toFixed(2) : (product.rate / 100).toFixed(2)}
							</p>
							<CheckIcon className={`h-8 w-8 text-bone ${selected !== product.id ? "opacity-0" : ""}`} />
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Products;
