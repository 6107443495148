import React from "react";
import useEndpoint from "../hooks/useEndpoint";
import Spinner from "./Spinner";
import { BanIcon, CheckIcon } from "@heroicons/react/outline";

const Locations = ({ hideAvailability, selected, setSelected }) => {
	const { locations, loading, error } = useEndpoint("/locations", "locations");

	if (loading) {
		return (
			<div className="border-2 border-primary rounded-xl p-3 flex items-center justify-center">
				<Spinner size="h-8 w-8" />
			</div>
		);
	}

	if (error) {
		return (
			<div className="border-2 border-primary rounded-xl p-3 flex items-center justify-center">
				<p className="text-primary font-bold text-center">
					Unable to load locations. Please try again later.
				</p>
			</div>
		);
	}

	return (
		<div className="border-2 border-primary rounded-xl flex flex-col items-center">
			{locations.map(location => {
				const unavailable = !hideAvailability && location.scooterCount === 0;

				return (
					<div key={location.id} className={
						`${selected === location.id ? "bg-primary" : "hover:bg-bone-darker"} ${unavailable ? "cursor-not-allowed" : ""} ` +
						"p-3 w-full flex flex-col border-b-2 border-primary cursor-pointer last:border-0 " +
						"transition duration-400 first:rounded-t-inner-xl last:rounded-b-inner-xl"
					} onClick={unavailable ? null : () => setSelected(location.id)}>
						<div className="flex justify-between items-center">
							<div>
								<p className={`${selected === location.id ? "text-bone" : "text-primary"} ${unavailable ? "grayscale" : ""} font-bold text-lg leading-none`}>
									{location.name}
								</p>
								{hideAvailability ? <></> : (
									<p className={`leading-none font-semibold mt-1 text-sm ${unavailable ? "text-red-500" : ""}`}>
										Available Scooters: {location.scooterCount}
									</p>
								)}
							</div>
							{unavailable ? (
								<BanIcon className="h-8 w-8 text-neutral-400" />
							) : (
								<CheckIcon className={`h-8 w-8 text-bone ${selected !== location.id ? "opacity-0" : ""}`} />
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Locations;
