import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Error from "./pages/Error";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Account from "./pages/Account";
import Extend from "./pages/Extend";
import Book from "./pages/Book";
import useEndpoint from "./hooks/useEndpoint";
import Pay from "./pages/Pay";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentSuccess from "./pages/PaymentSuccess";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const App = () => {
	const { user, loading, error } = useEndpoint("/account", "user");

	return (
		<Elements stripe={stripePromise} options={{
			fonts: [
				{
					cssSrc: "https://use.typekit.net/nbj3eyo.css"
				}
			]
		}}>
			<div className="flex flex-col min-h-screen">
				<Navbar user={user} loading={loading} />
				{error && error.data.code !== "NOT_LOGGED_IN" ? (
					<div className="flex-grow flex items-center">
						<Error code={error.statusCode} />
					</div>
				) : (
					<Routes>
						<Route path="/" exact element={<Home />} />
						<Route path="/login" element={<Login />} />
						<Route path="/signup" element={<SignUp />} />
						<Route path="/account" element={<Account />} />
						<Route path="/book" element={<Book />} />
						<Route path="/pay/:id/success" element={<PaymentSuccess />} />
						<Route path="/pay/:id" element={<Pay />} />
						<Route path="/extend/:id" element={<Extend />} />
						<Route path="*" element={<Error code={404} />} />
					</Routes>
				)}
			</div>
		</Elements>
	);
};

export default App;
