import React from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { UserIcon } from "@heroicons/react/solid";
import NavButton from "./NavButton";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";

const navigation = [
	{ name: "Home", href: "/" },
	{ name: "Book a Scootr", href: "/book" }
];

const Navbar = ({ user, loading }) => {
	const [, setToken] = useLocalStorage("token", null);

	return (
		<Disclosure as="nav" className="bg-bone-darker drop-shadow-md border-b-2 border-primary">
			{({ open }) => (
				<>
					<div className="mx-auto px-4 sm:px-6 lg:px-8">
						<div className="flex items-center justify-between h-20">
							<div className="flex items-center">
								<div className="flex-shrink-0">
									<Link to="/">
										<img
											className="h-7"
											src="/logo.png"
											alt="Logo"
										/>
									</Link>
								</div>
								<div className="hidden md:block">
									<div className="ml-10 flex items-baseline space-x-4">
										{user ? (
											navigation.map((item) => <NavButton item={item} key={item.href} />)
										) : <></>}
									</div>
								</div>
							</div>
							<div className="hidden md:block">
								<div className="ml-4 flex items-center md:ml-6 space-x-4">
									{user ? (
										<>
											<NavButton className="flex items-center" item={{
												href: "/account"
											}}>
												<UserIcon className="w-5 h-5 mr-2 inline-block" />
												<span>{user.firstName} {user.lastName}</span>
											</NavButton>
											<NavButton item={{
												name: "Logout"
											}} noFocus onClick={() => {
												setToken(null);
												window.location = "/";
											}} />
										</>
									) : loading ? (
										<></>
									) : (
										<>
											<NavButton noFocus item={{
												name: "Login",
												href: "/login"
											}} />
											<NavButton active item={{
												name: "Sign Up",
												href: "/signup"
											}} />
										</>
									)}
								</div>
							</div>
							<div className="-mr-2 flex md:hidden">
								<Disclosure.Button className="inline-flex items-center transition duration-250 justify-center p-2 rounded-md text-charcoal hover:bg-bone focus:outline-none focus:outline-none">
									<span className="sr-only">Open main menu</span>
									{open ? (
										<XIcon className="block h-6 w-6" aria-hidden="true" />
									) : (
										<MenuIcon className="block h-6 w-6" aria-hidden="true" />
									)}
								</Disclosure.Button>
							</div>
						</div>
					</div>

					<Disclosure.Panel className="md:hidden">
						<div className="px-4 pt-2 pb-4 space-y-2 sm:px-3">
							{user ? (
								<>
									<NavButton className="flex items-center w-full block" item={{
										href: "/account"
									}}>
										<UserIcon className="w-5 h-5 mr-2 inline-block" />
										<span>{user.firstName} {user.lastName}</span>
									</NavButton>
									<NavButton className="flex items-center w-full block" item={{
										name: "Logout"
									}} noFocus onClick={() => {
										setToken(null);
										window.location = "/";
									}} />
									{navigation.map((item) =>
										<NavButton item={item} key={item.href} className="w-full block" />
									)}
								</>
							) : loading ? (
								<div className={
									"text-primary hover:bg-gray-300 px-4 py-2 rounded-full text-md font-bold " +
									"border-2 border-primary transition duration-250 w-full flex items-center justify-center"
								}>
									<Spinner />
								</div>
							) : (
								<>
									<NavButton className="w-full block" noFocus item={{
										name: "Login",
										href: "/login"
									}} />
									<NavButton className="w-full block" active item={{
										name: "Sign Up",
										href: "/signup"
									}} />
								</>
							)}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
};

export default Navbar;
