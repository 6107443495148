import React from "react";
import { CashIcon, LightningBoltIcon, LocationMarkerIcon, SparklesIcon } from "@heroicons/react/outline";
import Container from "../components/Container";

const Home = () => {
	return (
		<div className="background-pattern">
			<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
				<div>
					<h2 className="text-5xl font-bold text-primary sm:text-6xl">
						scootr
					</h2>
					<p className="text-charcoal text-xl font-bold">
						The only scooter hire app you&apos;ll ever need.
					</p>
				</div>
				<div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
					<div className="inline-flex rounded-md shadow">
						<a
							href="/book"
							className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-bold rounded-md text-white bg-primary hover:bg-primary-darker"
						>
							Book a Scootr
						</a>
					</div>
				</div>
			</div>

			<div className="pb-12">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<div className="lg:text-center">
						<p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
							The easier way to hire a scooter
						</p>
						<p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
							Forget the hassle of other scooter hire apps.<br />Scootr streamlines the process so you can get riding, faster.
						</p>
					</div>

					<div className="mt-10">
						<dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
							<div className="relative">
								<dt>
									<div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
										<SparklesIcon className="h-6 w-6" />
									</div>
									<p className="ml-16 text-lg leading-6 font-extrabold text-gray-900">
										Competitive Pricing
									</p>
								</dt>
								<dd className="mt-2 ml-16 text-base text-gray-500">
									Scootr offers a variety of competitively priced plans for wherever and whenever you want to ride.
								</dd>
							</div>

							<div className="relative">
								<dt>
									<div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
										<CashIcon className="h-6 w-6" />
									</div>
									<p className="ml-16 text-lg leading-6 font-extrabold text-gray-900">
										No hidden fees
									</p>
								</dt>
								<dd className="mt-2 ml-16 text-base text-gray-500">
									You pay for the time you use, that&apos;s it. It couldn&apos;t get any simpler!
								</dd>
							</div>

							<div className="relative">
								<dt>
									<div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
										<LocationMarkerIcon className="h-6 w-6" />
									</div>
									<p className="ml-16 text-lg leading-6 font-extrabold text-gray-900">
										Plenty of Locations
									</p>
								</dt>
								<dd className="mt-2 ml-16 text-base text-gray-500">
									With pick-up points spread all over Leeds, you&apos;ll never be far from a Scootr.
								</dd>
							</div>

							<div className="relative">
								<dt>
									<div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
										<LightningBoltIcon className="h-6 w-6" />
									</div>
									<p className="ml-16 text-lg leading-6 font-extrabold text-gray-900">
										Get riding in 5 minutes
									</p>
								</dt>
								<dd className="mt-2 ml-16 text-base text-gray-500">
									After you pick a destination and a duration your Scootr is released as soon as you&apos;ve paid!
								</dd>
							</div>
						</dl>
					</div>
				</div>
			</div>

			<div className="py-12 flex items-center justify-center">
				<Container wide>
					<div className="grid grid-cols-1 sm:grid-cols-3 flex-wrap gap-4 sm:gap-8">
						<div className="w-full p-2 sm:p-6 bg-white rounded-lg shadow-lg items-center border-2 border-primary">
							<p className="text-md sm:text-lg text-center uppercase font-bold text-charcoal">Users</p>
							<p className="w-full mt-2 text-primary text-primary text-3xl sm:text-4xl text-center font-bold">1000+</p>
						</div>
						<div className="w-full p-2 sm:p-6 bg-white rounded-lg shadow-lg items-center border-2 border-primary">
							<p className="text-md sm:text-lg text-center uppercase font-bold text-charcoal">Rides Completed</p>
							<p className="w-full mt-2 text-primary text-primary text-3xl sm:text-4xl text-center font-bold">3780</p>
						</div>
						<div className="w-full p-2 sm:p-6 bg-white rounded-lg shadow-lg items-center border-2 border-primary">
							<p className="text-md sm:text-lg text-center uppercase font-bold text-charcoal">Average Rating</p>
							<p className="w-full mt-2 text-primary text-primary text-3xl sm:text-4xl text-center font-bold">4.8 ⭐</p>
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
};

export default Home;
