import React from "react";

const Input = (props) => {
	const { className, ...otherProps } = props;

	return (
		<input {...otherProps}
			className={
				"bg-bone-darker placeholder:focus:text-charcoal text-charcoal transition " +
				"duration-250 px-2 outline-none py-1 rounded-lg border-2 border-neutral-400 " +
				`focus:border-primary disabled:cursor-not-allowed w-full ${className ? className : ""}`
			}
		/>
	);
};

export default Input;
