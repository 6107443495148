import React, { useState, useEffect } from "react";
import {
	CircularProgressbarWithChildren,
	buildStyles
} from "react-circular-progressbar";

const plural = (value, unit) => {
	if (value === 1)
		return value + " " + unit;
	else if (value > 1)
		return value + " " + unit + "s";

	return null;
};

const formatSeconds = (s) => {
	// create array of day, hour, minute and second values
	const fm = [
		Math.floor(s / (3600 * 24)),
		Math.floor(s % (3600 * 24) / 3600),
		Math.floor(s % 3600 / 60),
		Math.floor(s % 60)
	];

	return fm.map(function (v, i) {
		let returnVal;
		if (v) {
			// add the relevant value suffix
			if (i === 0)
				returnVal = plural(v, "day");
			else if (i === 1)
				returnVal = plural(v, "hour");
			else if (i === 2)
				returnVal = plural(v, "minute");
			else if (i === 3)
				returnVal = plural(v, "second");

			return returnVal;
		}

		return null;
	}).filter(x => x).join(", ");
};

const Timer = ({ startTime, duration }) => {
	const [{ progress }, setState] = useState({
		progress: 100 - (((Date.now() - startTime) / duration) * 100)
	});

	useEffect(() => {
		setInterval(() => {
			setState({
				progress: 100 - (((Date.now() - startTime) / duration) * 100)
			});
		}, 100);
	}, []);

	const timeLeft = formatSeconds(((startTime + duration) - Date.now()) / 1000);

	return (
		<CircularProgressbarWithChildren
			value={progress}
			styles={buildStyles({
				trailColor: "#d0d0dc",
				pathColor: "#ea580c",
				strokeLinecap: "round"
			})}
		>
			<div className="text-2xl text-center w-3/4">
				{!timeLeft ? "Time's Up!" : timeLeft}
			</div>
		</CircularProgressbarWithChildren>
	);
};

export default Timer;
