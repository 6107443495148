import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import Spinner from "../components/Spinner";
import Error from "./Error";
import PaymentForm from "../components/PaymentForm";
import Container from "../components/Container";

const Pay = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [token] = useLocalStorage("token", null);
	const [booking, setBooking] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);

				let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/bookings/${id}`, {
					headers: {
						authorization: "Bearer " + token
					}
				});

				let body = await response.json();

				if (response.ok)
					setBooking(body.booking);
				else
					setError(response.status);

				setLoading(false);
			} catch (err) {
				setLoading(false);

				if (!err.response)
					err.response = { message: "An unknown error occurred" };
				else if (!err.response.message)
					err.response.message = "An unknown error occurred";

				setError(500);
			}
		})();
	}, [id]);

	if (loading) {
		return (
			<div className="flex justify-center items-center flex-grow">
				<Spinner size="h-12 w-12" />
			</div>
		);
	}

	if (error) {
		if (error === 404) {
			navigate("/book");
			return null;
		}

		return (
			<div className="flex justify-center items-center flex-grow">
				<Error code={error} />
			</div>
		);
	}

	if (booking.status !== 0) {
		navigate("/book");
		return null;
	}

	return (
		<div className="flex justify-center items-center mt-8 px-2">
			<Container small>
				<h3 className="text-2xl text-center font-bold text-primary">Complete your Booking</h3>
				<p className="text-center text-lg font-bold text-primary mb-4">
					{booking.product.name} &bull; £{(booking.product.rate / 100).toFixed(2)}
				</p>
				<PaymentForm booking={booking} />
			</Container>
		</div>
	);
};

export default Pay;
