import React from "react";
import { Link, useParams } from "react-router-dom";
import Container from "../components/Container";
import { ExclamationIcon, TicketIcon } from "@heroicons/react/outline";
import useEndpoint from "../hooks/useEndpoint";
import Spinner from "../components/Spinner";
import Error from "./Error";

const PaymentSuccess = () => {
	const { id } = useParams();
	const { booking, loading, error } = useEndpoint(`/bookings/${id}`, "booking");

	if (loading) {
		return (
			<div className="flex justify-center items-center flex-grow">
				<Spinner size="h-12 w-12" />
			</div>
		);
	}

	if (error) {
		return (
			<Error code={error.statusCode} />
		);
	}

	if (booking.status !== 1) {
		return (
			<div className="flex items-center text-center justify-center text-charcoal flex-grow flex-col h-full px-2">
				<Container small className="items-center">
					<ExclamationIcon className="inline-block w-16 h-16 text-amber-500" />
					<p className="text-lg font-bold">
						Your booking hasn&apos;t been finalised yet. If you&apos;ve just paid, please wait a few moments and refresh the page.
					</p>
				</Container>
			</div>
		);
	}

	return (
		<div className="flex items-center text-center justify-center text-charcoal flex-grow flex-col h-full px-2">
			<Container className="items-center">
				<TicketIcon className="inline-block w-16 h-16 text-green-500" />
				<h1 className="font-extrabold text-green-500 text-4xl mt-3 mb-4">
					Thank You!
				</h1>
				<p className="text-lg font-bold">
					Your booking has been confirmed. You should receive an email shortly containing your receipt.
					You can also visit your <Link to="/account" className="text-primary font-bold">account page</Link>  to view booking details.
				</p>
				<h2 className="mt-8 text-primary text-2xl font-bold">Your Scootr number is: {booking.scooter.number}</h2>
				<p className="font-bold">Head to the pick-up point and enjoy your ride!</p>
			</Container>
		</div>
	);
};

export default PaymentSuccess;
