import React, { useState } from "react";
import Container from "../components/Container";
import Input from "../components/Input";
import useEndpoint from "../hooks/useEndpoint";
import Spinner from "../components/Spinner";
import Button from "../components/Button";
import { ArrowRightIcon, LockClosedIcon } from "@heroicons/react/solid";
import useToasts from "../hooks/useToasts";
import useLocalStorage from "../hooks/useLocalStorage";
import Badge from "../components/Badge";
import dayjs from "dayjs";

const Account = () => {
	const toast = useToasts();
	const [{ currentPassword, newPassword, confirmNewPassword }, setState] = useState({
		currentPassword: null,
		newPassword: null,
		confirmNewPassword: null
	});
	const [token] = useLocalStorage("token", null);
	const [changePasswordLoading, setChangePasswordLoading] = useState(false);
	const { user, loading, error } = useEndpoint("/account", "user");
	const { bookings, loading: bookingsLoading, error: bookingsError } = useEndpoint("/bookings", "bookings");

	if (loading || error) {
		return (
			<div className="flex justify-center items-center flex-grow">
				<Spinner size="h-12 w-12" />
			</div>
		);
	}

	const handleInputChange = event => {
		setState((state) => ({
			...state,
			[event.target.name]: event.target.value
		}));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			if (!currentPassword) {
				toast("Error!", "Please enter your current password", 5000, "bg-red-500");
				return;
			}

			if (!newPassword) {
				toast("Error!", "Please enter a new password", 5000, "bg-red-500");
				return;
			}

			if (newPassword !== confirmNewPassword) {
				toast("Error!", "Passwords do not match", 5000, "bg-red-500");
				return;
			}

			setChangePasswordLoading(true);

			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/auth/change-password`, {
				method: "POST",
				body: JSON.stringify({
					password: currentPassword,
					newPassword,
					confirmNewPassword
				}),
				headers: {
					"content-type": "application/json",
					authorization: "Bearer " + token
				}
			});

			let body = await response.json();

			if (response.ok)
				toast("Success!", "Password changed successfully", 5000, "bg-blue-500");
			else
				toast("Error!", body.message, 5000, "bg-red-500");

			setChangePasswordLoading(false);
		} catch (err) {
			setChangePasswordLoading(false);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	return (
		<div className="flex justify-center flex-grow pt-4 sm:pt-8 p-2">
			<Container>
				<h2 className="text-primary text-2xl font-bold mb-2">Account Details</h2>
				<div className="rounded-lg border-2 border-primary p-3 mb-6">
					<div className="flex flex-wrap gap-4 w-full mb-2">
						<div className="flex-grow">
							<label className="text-md text-primary font-semibold">
								First Name
								<Input type="text" value={user.firstName} disabled className="mt-1" />
							</label>
						</div>
						<div className="flex-grow">
							<label className="text-md text-primary font-semibold">
								Last Name
								<Input type="text" value={user.lastName} disabled className="mt-1" />
							</label>
						</div>
					</div>
					<label className="text-md text-primary font-semibold">
						Email
						<Input type="text" value={user.email} disabled className="mt-1" />
					</label>
				</div>
				<form onSubmit={handleSubmit}>
					<h2 className="text-primary text-2xl font-bold mb-2">Change Password</h2>
					<div className="rounded-lg border-2 border-primary p-3 mb-6">
						<label className="text-md text-primary font-semibold">
							Current Password
							<Input name="currentPassword" type="password" autoComplete="current-password" className="mb-3" onInput={handleInputChange} />
						</label>
						<label className="text-md text-primary font-semibold">
							New Password
							<Input name="newPassword" type="password" autoComplete="new-password" className="mb-3" onInput={handleInputChange} />
						</label>
						<label className="text-md text-primary font-semibold">
							Confirm New Password
							<Input name="confirmNewPassword" type="password" autoComplete="new-password" className="mb-3" onInput={handleInputChange} />
						</label>
						<Button
							type="submit"
							text="Change Password" className="w-full"
							iconLeft={<LockClosedIcon className="w-5 h-5 mr-1" />}
							loading={changePasswordLoading}
						/>
					</div>
				</form>
				<h2 className="text-primary text-2xl font-bold mb-2">Your Bookings</h2>
				<div className="rounded-lg border-2 border-primary mb-6">
					{bookingsLoading ? (
						<Spinner size="m-3 h-8 w-8" />
					) : bookingsError ? (
						<p className="text-primary font-bold text-center">
							Unable to load bookings. Please try again later.
						</p>
					) : (
						bookings.filter(b => b.active || (!b.active && b.status === 1)).sort((a, b) => b.active - a.active).map(booking => (
							<div key={booking.id} className="border-b-2 border-primary last:border-0 p-3">
								<div className="flex items-center justify-between">
									<span className="font-bold flex items-center">
										{booking.startLocation.name}
										<ArrowRightIcon className="inline-block mx-1 w-4 h-4" />
										{booking.endLocation.name}
									</span>
									{!booking.active && booking.status === 1 ? (
										<Badge text="Complete" />
									) : (
										<Badge color="bg-yellow-400 text-charcoal" text="In Progress" />
									)}
								</div>
								<div className="mb-2">
									{dayjs(booking.date).format("DD/MM/YYYY HH:mm")}
								</div>
								<div className="flex items-center justify-between gap-4">
									<span className="flex-shrink-0 font-bold">{booking.product.name}</span>
									<div className="flex-grow border-b border-charcoal" />
									<span className="font-bold">£{(booking.totalCost / 100).toFixed(2)}</span>
								</div>
							</div>
						))
					)}
				</div>
			</Container>
		</div>
	);
};

export default Account;
