import React, { useEffect, useState } from "react";
import Input from "../components/Input";
import Container from "../components/Container";
import Button from "../components/Button";
import { LockClosedIcon } from "@heroicons/react/solid";
import useLocalStorage from "../hooks/useLocalStorage";
import { Link, useNavigate } from "react-router-dom";
import useToasts from "../hooks/useToasts";
import { mutate } from "swr";

const Login = () => {
	const navigate = useNavigate();
	const [token, setToken] = useLocalStorage("token", null);
	const toast = useToasts();
	const [loading, setLoading] = useState(false);
	const [{ email, password }, setState] = useState({
		email: null,
		password: null
	});

	const handleInputChange = event => {
		setState((state) => ({
			...state,
			[event.target.name]: event.target.value
		}));
	};

	const handleLogin = async (event) => {
		event.preventDefault();

		try {
			if (!email) {
				toast("Error!", "Please enter your email", 5000, "bg-red-500");
				return;
			}

			if (!password) {
				toast("Error!", "Please enter your password", 5000, "bg-red-500");
				return;
			}

			setLoading(true);

			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/auth/login`, {
				method: "POST",
				body: JSON.stringify({
					email,
					password
				}),
				headers: {
					"content-type": "application/json"
				}
			});

			let body = await response.json();

			if (response.ok) {
				setToken(body.token);
				await mutate("/account");
			} else
				toast("Error!", body.message, 5000, "bg-red-500");

			setLoading(false);
		} catch (err) {
			setLoading(false);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	useEffect(() => {
		if (token)
			navigate("/");
	}, [token]);

	return (
		<div className="flex items-center justify-center flex-grow">
			<Container small className="flex items-center p-3">
				<form onSubmit={handleLogin}>
					<h3 className="text-3xl font-bold text-center text-primary">Welcome Back!</h3>
					<p className="mb-6 text-center">
						Need an account? <Link className="text-primary font-bold hover:text-primary-darker" to="/signup">Sign Up</Link>
					</p>
					<label className="ml-1 text-md text-primary font-semibold">
						Email
						<Input type="email" autoComplete="email" className="mb-4" name="email" onInput={handleInputChange} />
					</label>

					<label className="ml-1 text-md text-primary font-semibold">
						Password
						<Input type="password" autoComplete="current-password" className="mb-4" name="password" onInput={handleInputChange} />
					</label>
					<Button
						type="submit"
						className="w-full"
						text="Login"
						iconLeft={<LockClosedIcon className="w-5 h-5 mr-2" />}
						loading={loading}
					/>
				</form>
			</Container>
		</div>
	);
};

export default Login;
