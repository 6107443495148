import React, { useEffect, useState } from "react";
import Input from "../components/Input";
import Container from "../components/Container";
import Button from "../components/Button";
import { KeyIcon } from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import useToasts from "../hooks/useToasts";
import { mutate } from "swr";

const SignUp = () => {
	const navigate = useNavigate();
	const [token, setToken] = useLocalStorage("token", null);
	const toast = useToasts();
	const [loading, setLoading] = useState(false);
	const [{ email, firstName, lastName, password, confirmPassword, discount }, setState] = useState({
		email: null,
		firstName: null,
		lastName: null,
		password: null,
		confirmPassword: null,
		discount: "0"
	});

	const handleInputChange = event => {
		setState((state) => ({
			...state,
			[event.target.name]: event.target.value
		}));
	};

	const handleDiscountChange = () => {
		if (discount === "0")
			setState({ discount: "1" });
		else
			setState({ discount: "0" });
	};

	const handleSignUp = async event => {
		event.preventDefault();

		try {
			if (!email) {
				toast("Error!", "Please enter your email address", 5000, "bg-red-500");
				return;
			}

			if (!firstName) {
				toast("Error!", "Please enter your first name", 5000, "bg-red-500");
				return;
			}

			if (!lastName) {
				toast("Error!", "Please enter your last name", 5000, "bg-red-500");
				return;
			}

			if (!password) {
				toast("Error!", "Please enter your password", 5000, "bg-red-500");
				return;
			}

			if (password !== confirmPassword) {
				toast("Error!", "Passwords do not match", 5000, "bg-red-500");
				return;
			}

			setLoading(true);

			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/auth/signup`, {
				method: "POST",
				body: JSON.stringify({
					email,
					firstName,
					lastName,
					password,
					confirmPassword,
					discount
				}),
				headers: {
					"content-type": "application/json"
				}
			});

			let body = await response.json();

			if (response.ok) {
				setToken(body.token);
				await mutate("/account");
			} else
				toast("Error!", body.message, 5000, "bg-red-500");

			setLoading(false);
		} catch (err) {
			setLoading(false);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	useEffect(() => {
		if (token)
			navigate("/");
	}, [token]);

	return (
		<div className="flex items-center justify-center flex-grow">
			<Container small className="flex items-center p-3">
				<h3 className="text-3xl font-bold text-primary">Welcome!</h3>
				<p className="mb-6">
					Already have an account? <Link className="text-primary font-bold hover:text-primary-darker" to="/login">Login</Link>
				</p>
				<form onSubmit={handleSignUp}>
					<label className="ml-1 text-md text-primary font-semibold">
						Email
						<Input type="email" name="email" className="mb-4" onInput={handleInputChange} />
					</label>

					<label className="ml-1 text-md text-primary font-semibold">
						First Name
						<Input type="text" name="firstName" className="mb-4" onInput={handleInputChange} />
					</label>

					<label className="ml-1 text-md text-primary font-semibold">
						Last Name
						<Input type="text" name="lastName" className="mb-4" onInput={handleInputChange} />
					</label>

					<label className="ml-1 text-md text-primary font-semibold">
						Password
						<Input type="password" name="password" className="mb-4" onInput={handleInputChange} />
					</label>

					<label className="ml-1 text-md text-primary font-semibold">
						Current Password
						<Input type="password" name="confirmPassword" className="mb-4" onInput={handleInputChange} />
					</label>

					<div className="flex w-full">
						<Input type="checkbox" name="discount" className="mb-4 w-4 mr-4 mt-1" onChange={handleDiscountChange} />
						<p>I am a student or elderly person</p>
					</div>

					<Button
						type="submit"
						className="w-full"
						text="Sign Up"
						iconLeft={<KeyIcon className="w-5 h-5 mr-2" />}
						loading={loading}
					/>
				</form>
			</Container>
		</div>
	);
};

export default SignUp;
